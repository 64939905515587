export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const PERMITS_CHART_CONFIG = {
  aquifer: {
    label: "Permitted Gallons by Aquifer",
    endpoint: "/api/ui-dashboard-permits-by-aquifer-group",
    labelKey: "aquifer_group_name",
    csvHeaders: ["Aquifer Group", "Total Gallons"],
    dataKeys: ["aquifer_group_name", "total_permitted_gallons"],
  },
  tier: {
    label: "Permitted Gallons by Permit Tier",
    endpoint: "/api/ui-dashboard-permits-by-tier",
    labelKey: "permit_tier_name",
    csvHeaders: ["Permit Tier", "Total Gallons"],
    dataKeys: ["permit_tier_name", "total_permitted_gallons"],
  },
  status: {
    label: "Permitted Gallons by Permit Status",
    endpoint: "/api/ui-dashboard-permits-by-permit-status",
    labelKey: "permit_status_desc",
    csvHeaders: ["Permit Status", "Total Gallons"],
    dataKeys: ["permit_status_desc", "total_permitted_gallons"],
  },
};
