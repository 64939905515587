import React from "react";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  TitleBar,
  GridContainer,
  GridItem,
} from "./ui";

const CreateWellPage = function () {
  return (
    <PageRoot>
      <Helmet title="Create Well | Field Services" />

      <PageAppBar />

      <TitleBar
        title="Create a Well"
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Return to Home"
      />

      <PageContainer>
        <GridContainer>
          <GridItem></GridItem>
        </GridContainer>
      </PageContainer>
    </PageRoot>
  );
};

export default CreateWellPage;
