import { useQuery } from "react-query";
import { useAxiosInstance } from "./useAxiosInstance";
import { USE_QUERY_OPTIONS } from "../lib/constants";

export const useWells = () => {
  const axiosInstance = useAxiosInstance();

  return useQuery(
    "wells",
    async () => {
      const { data } = await axiosInstance.get("/wells");
      return data;
    },
    USE_QUERY_OPTIONS
  );
};
