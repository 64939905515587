import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS, PERMITS_CHART_CONFIG } from "../constants";

export const usePermitsData = () => {
  const [chartType, setChartType] = useState("aquifer");
  const [selectedYear, setSelectedYear] = useState(null);

  const { endpoint, labelKey } = PERMITS_CHART_CONFIG[chartType];

  const { data, isLoading, error } = useQuery(
    ["permits-data", chartType],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}${endpoint}`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );

  const fiscalYears = useMemo(
    () =>
      data
        ? Array.from(new Set(data.map((item) => item.fiscal_year))).sort(
            (a, b) => b - a
          )
        : [],
    [data]
  );

  useEffect(() => {
    if (
      fiscalYears.length &&
      (selectedYear === null || !fiscalYears.includes(selectedYear))
    ) {
      setSelectedYear(fiscalYears[0]); // Reset to most recent year if invalid
    }
  }, [fiscalYears, selectedYear]);

  const filteredData = useMemo(
    () =>
      data ? data.filter((item) => item.fiscal_year === selectedYear) : [],
    [data, selectedYear]
  );

  const sortedData = useMemo(
    () =>
      filteredData.sort(
        (a, b) => b.total_permitted_gallons - a.total_permitted_gallons
      ),
    [filteredData]
  );

  const labels = sortedData.map((item) => item[labelKey]);
  const chartData = sortedData.map((item) => item.total_permitted_gallons);

  return {
    chartType,
    setChartType,
    data: sortedData,
    labels,
    chartData,
    isLoading,
    error,
    fiscalYears,
    selectedYear,
    setSelectedYear,
  };
};
