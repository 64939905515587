import React, { useEffect, useState, memo } from "react";
import { useQuery } from "react-query";
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  TextField,
  Button,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Avatar,
  Paper,
} from "@material-ui/core";
import styled from "styled-components/macro";
import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { NumberFormatCustom } from "../../dataManagement/wells/wellsManagement/utils";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const IntersectWells = () => {
  let { lat, lon, distance } = useParams();

  const [editorValues, setEditorValues] = useState({
    lat,
    lon,
    distance,
  });
  const [userLat, setUserLat] = useState("");
  const [userLon, setUserLon] = useState("");
  const [userDistance, setUserDistance] = useState("");

  const isUserInputProvided = !!(lat || lon || distance);

  const { data, isFetching, error, refetch } = useIntersectWellsQuery(
    editorValues,
    isUserInputProvided
  );

  const handleSubmit = () => {
    if (userLat && userLon && userDistance) {
      setEditorValues({
        lat: userLat,
        lon: userLon,
        distance: userDistance,
      });
    }
  };

  useEffect(() => {
    if (editorValues.distance && editorValues.lat && editorValues.lon) {
      refetch().then();
    }
  }, [editorValues.distance, editorValues.lat, editorValues.lon, refetch]);

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Adjacent Wells Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Adjacent Wells Report
      </Typography>

      {isUserInputProvided && (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to="/">
            Interactive Map
          </Link>
          <Link
            component={NavLink}
            exact
            to="/public-tools/tools/adjacent-wells"
          >
            Adjacent Wells
          </Link>
          {isUserInputProvided && <Typography>Results</Typography>}
        </Breadcrumbs>
      )}

      <Divider my={6} />

      {!isUserInputProvided && (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <Grid item>
            <TextField
              error={!userLat || userLat > 90 || userLat < -90}
              helperText={
                !userLat && userLat !== 0
                  ? "*Required field"
                  : userLat > 90 || userLat < -90
                  ? "Invalid coordinate"
                  : ""
              }
              required
              label="Latitude"
              variant="outlined"
              value={userLat ?? ""}
              InputProps={{
                inputProps: {
                  config: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                },
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => setUserLat(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              error={!userLon || userLon > 180 || userLon < -180}
              helperText={
                !userLon && userLon !== 0
                  ? "*Required field"
                  : userLon > 180 || userLon < -180
                  ? "Invalid coordinate"
                  : ""
              }
              label="Longitude"
              variant="outlined"
              InputProps={{
                inputProps: {
                  config: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                },
                inputComponent: NumberFormatCustom,
              }}
              value={userLon ?? ""}
              onChange={(e) => setUserLon(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
              error={!userDistance}
              helperText={!userDistance ? "*Required field" : ""}
              required
              label="Distance (ft)"
              variant="outlined"
              value={userDistance ?? ""}
              onChange={(e) => setUserDistance(e.target.value)}
              InputProps={{
                inputProps: {
                  onKeyPress: (e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!userDistance || !userLon || !userLat}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}

      {data?.length > 0 && (
        <Typography variant="subtitle1" gutterBottom>
          {`There are ${
            data.length
          } wells within ${(+editorValues.distance).toFixed(
            2
          )} feet of the location ${parseFloat(editorValues.lat).toFixed(
            6
          )}, ${parseFloat(editorValues.lon).toFixed(6)}`}
        </Typography>
      )}
      {
        <Paper style={{ padding: "16px" }}>
          <TableWrapper>
            {isFetching ? (
              <Loader />
            ) : data && data.length > 0 ? (
              <RenderTable data={data} />
            ) : !isUserInputProvided && !data ? (
              <Typography variant="body1" gutterBottom>
                Provide a latitude, longitude, and distance in feet to search
                for wells within a certain distance of a location.
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                {`There are no wells within ${(+editorValues.distance).toFixed(
                  2
                )} feet of the location ${parseFloat(editorValues.lat).toFixed(
                  6
                )}, ${parseFloat(editorValues.lon).toFixed(6)}`}
              </Typography>
            )}
          </TableWrapper>
        </Paper>
      }
    </>
  );
};

const RenderTable = memo(({ data }) => {
  const columns = [
    {
      title: "Distance (ft)",
      field: "Distance",
      render: (rowData) => `${(rowData.Distance / 3).toFixed(2)}`,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "District Well Id",
      field: "well_id",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Name",
      field: "well_name",
      cellStyle: {
        minWidth: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Status",
      field: "well_status",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Class",
      field: "well_class",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Subclass",
      field: "well_subclass",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Management Zone",
      field: "management_zone",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Aquifer Group",
      field: "aquifer_group",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Use",
      field: "primary_use",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Owner",
      field: "owner_info",
      cellStyle: {
        minWidth: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Permit Info",
      field: "permits_info",
    },
    {
      title: "Date Drilled",
      field: "drilled_date",
      type: "date",
    },
    {
      title: "Well Depth, Ft",
      field: "well_depth_ft",
    },
    {
      title: "Well Address",
      field: "well_address",
      cellStyle: {
        minWidth: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Documents",
      field: "attached_documents",
      render: (rowData) => {
        if (!rowData.attached_documents) return "";
        const splitArray = rowData.attached_documents.split(",");
        return splitArray.map((item, i) => {
          const label = item.split(";")[0];
          const url = item.split(";")[1];
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#5A6373",
                  marginRight: "8px",
                  fontSize: "12px",
                  width: "20px",
                  height: "20px",
                }}
              >
                {i + 1}
              </Avatar>

              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                {label}
              </Link>
            </div>
          );
        });
      },
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
    },
    {
      title: "Photos",
      field: "attached_photos",

      render: (rowData) => {
        if (!rowData.attached_photos) return "";
        const splitArray = rowData.attached_photos.split(",");
        return splitArray.map((item, i) => {
          const label = item.split(";")[0];
          const url = item.split(";")[1];
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#5A6373",
                  marginRight: "8px",
                  fontSize: "12px",
                  width: "20px",
                  height: "20px",
                }}
              >
                {i + 1}
              </Avatar>

              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                {label}
              </Link>
            </div>
          );
        });
      },
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
    },
    {
      title: "TX Grid",
      field: "tx_grid",
    },
    {
      title: "Latitude",
      field: "lat_dd",
    },
    {
      title: "Longitude",
      field: "lon_dd",
    },
  ];

  return (
    <Table
      label="GPU Select Tool"
      columns={columns}
      data={data}
      height="100%"
      pageSize={30}
      options={{ filtering: true }}
    />
  );
});

// Custom hook for API query
const useIntersectWellsQuery = (editorValues, isUserInputProvided) => {
  return useQuery(
    [`adjacent-wells`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/adjacent-wells/${editorValues.lat}/${editorValues.lon}/${editorValues.distance}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: isUserInputProvided,
      cacheTime: 0,
    }
  );
};

export default IntersectWells;
