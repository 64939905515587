export const BASE_PATH = "/field-services";

export const FieldServicesAppRoutes = {
  DASHBOARD: () => "/dashboard",
  HOME: () => BASE_PATH,
  CREATE_WELL: () => `${BASE_PATH}/create-well`,
  SELECT_WELL: () => `${BASE_PATH}/select-well`,
  METER_READING: () => `${BASE_PATH}/meter-reading`,
  WELL_ACTIONS: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}` : BASE_PATH,
  WELL_INSPECTION: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/inspection` : BASE_PATH,
  WELL_RECORD: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/record` : BASE_PATH,
  WATER_LEVELS: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/water-levels` : BASE_PATH,
  WATER_QUALITY: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/water-quality` : BASE_PATH,
  UPLOAD_PHOTOS: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/upload-photos` : BASE_PATH,
  FIELD_NOTES: (wellId) =>
    wellId ? `${BASE_PATH}/well-actions/${wellId}/field-notes` : BASE_PATH,
};

export const HOME_ACTIONS = [
  { label: "Create a Well", route: FieldServicesAppRoutes.CREATE_WELL() },
  { label: "Select a Well", route: FieldServicesAppRoutes.SELECT_WELL() },
  {
    label: "Enter a Meter Reading",
    route: FieldServicesAppRoutes.METER_READING(),
  },
];

export const WELL_ACTIONS = (wellId) => [
  {
    label: "Well Inspection",
    route: FieldServicesAppRoutes.WELL_INSPECTION(wellId),
  },
  { label: "Well Record", route: FieldServicesAppRoutes.WELL_RECORD(wellId) },
  { label: "Water Levels", route: FieldServicesAppRoutes.WATER_LEVELS(wellId) },
  {
    label: "Water Quality",
    route: FieldServicesAppRoutes.WATER_QUALITY(wellId),
  },
  {
    label: "Upload Photos",
    route: FieldServicesAppRoutes.UPLOAD_PHOTOS(wellId),
  },
  { label: "Field Notes", route: FieldServicesAppRoutes.FIELD_NOTES(wellId) },
];

export const USE_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  staleTime: Infinity,
  cacheTime: Infinity,
};
