import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  TitleBar,
  ErrorCard,
  ContentSkeleton,
  ContentPlaceholder,
} from "./ui";
import { useSelectedWell } from "../hooks/useSelectedWell";

const WellInspectionPage = function () {
  const { wellId } = useParams();
  const { data, isFetching, error, refetch } = useSelectedWell(wellId);

  console.log(data);

  return (
    <PageRoot>
      <Helmet title="Well Inspection | Field Services" />

      <PageAppBar />

      <TitleBar
        title={`Well Inspection: ${wellId}`}
        buttonPath={FieldServicesAppRoutes.WELL_ACTIONS(wellId)}
        buttonLabel="Return to Well Actions"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title={`Failed to Load Data for Well ${wellId}`}
            message={
              error?.message ||
              "An unexpected error occurred. Please try again."
            }
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : isFetching ? (
          <ContentSkeleton />
        ) : (
          <ContentPlaceholder wellId={wellId} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WellInspectionPage;
