import React from "react";
import {
  Grid,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import WellCountSummaryChart from "./sections/WellCountSummaryChart";
import PermittedVolumesOverTimeChart from "./sections/PermittedVolumesOverTimeChart";
import MonthlyPumpageTrackingChart from "./sections/MonthlyPumpageTrackingChart";
import LateMeterReadingsTable from "./sections/LateMeterReadingsTable";
import PermitsCharts from "./sections/PermitsCharts";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Dashboard = () => {
  const { user } = useAuth0();

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, {user?.name}!
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MonthlyPumpageTrackingChart />
        </Grid>
        <Grid item xs={12} xl={6}>
          <LateMeterReadingsTable />
        </Grid>
        <Grid item xs={12} xl={6}>
          <PermittedVolumesOverTimeChart />
        </Grid>
        <Grid item xs={12} xl={6}>
          <PermitsCharts />
        </Grid>
        <Grid item xs={12} xl={6}>
          <WellCountSummaryChart />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;
