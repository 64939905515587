import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes, WELL_ACTIONS } from "../lib/constants";
import {
  NavigationButton,
  PageContainer,
  PageAppBar,
  GridContainer,
  GridItem,
  PageRoot,
  TitleBar,
  ErrorCard,
} from "./ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import Skeleton from "@material-ui/lab/Skeleton";

const WellActionsPage = function () {
  const { wellId } = useParams();
  const { data, isFetching, error, refetch } = useSelectedWell(wellId);

  console.log(data);

  return (
    <PageRoot>
      <Helmet title="Well Actions | Field Services" />

      <PageAppBar />

      <TitleBar
        title={`Selected Well: ${wellId}`}
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Switch Well"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title={`Failed to Load Data for Well ${wellId}`}
            message={
              error?.message ||
              "An unexpected error occurred. Please try again."
            }
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : (
          <GridContainer>
            {WELL_ACTIONS(wellId).map((action, index) => (
              <GridItem key={index}>
                {isFetching ? (
                  <Skeleton variant="rect" style={{ flexGrow: 1 }} />
                ) : (
                  <NavigationButton to={action.route} label={action.label} />
                )}
              </GridItem>
            ))}
          </GridContainer>
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WellActionsPage;
