import React from "react";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Box } from "@material-ui/core";
import { useWells } from "../hooks/useWells";
import { FieldServicesAppRoutes } from "../lib/constants";
import { PageAppBar, PageContainer, PageRoot, TitleBar, ErrorCard } from "./ui";
import { Helmet } from "react-helmet-async";
import Skeleton from "@material-ui/lab/Skeleton";

const SelectWellPage = () => {
  const history = useHistory();
  const { data: wells, isLoading, isFetching, error, refetch } = useWells();

  const handleSelect = (event, selectedWell) => {
    if (selectedWell && selectedWell.well_id) {
      history.push(FieldServicesAppRoutes.WELL_ACTIONS(selectedWell.well_id));
    }
  };

  return (
    <PageRoot>
      <Helmet title="Select Well | Field Services" />

      <PageAppBar />

      <TitleBar
        title="Select a Well"
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Return to Home"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title="Failed to Load Wells"
            message={
              error?.message ||
              "An unexpected error occurred. Please try again."
            }
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : isLoading ? (
          <Box width="300px" mt="8px">
            <Skeleton variant="rect" height={56} />
          </Box>
        ) : (
          <Autocomplete
            options={wells || []}
            getOptionLabel={(option) =>
              option?.well_name
                ? `${option.well_name} (#${option.well_id || "N/A"})`
                : `Well #${option.well_id || "N/A"}`
            }
            onChange={handleSelect}
            renderInput={(params) => (
              <TextField {...params} label="Select a Well" variant="outlined" />
            )}
            style={{ width: 300, marginTop: "8px" }}
          />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default SelectWellPage;
