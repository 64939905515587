import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { getLookupForDataSortedByField } from "../../../../../utils";
import { useMemo } from "react";

export const useWells = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedWell, setSelectedWell] = useStateIfMounted(null);
  const [selectedWellLabel, setSelectedWellLabel] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-list-wm-wells"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wm-wells`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const wellClassificationLookup = useMemo(
    () => getLookupForDataSortedByField(data, "well_class"),
    [data]
  );

  const WELLS_TABLE_COLUMNS = useMemo(
    () => [
      {
        title: "Well Classification",
        field: "well_class",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
        lookup: wellClassificationLookup,
      },
      {
        title: "District Well Id",
        field: "district_well_id",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      {
        title: "Well Name",
        field: "well_name",
        cellStyle: {
          width: 400,
          minWidth: 400,
        },
      },
      {
        title: "Is Published",
        field: "is_published",
        defaultFilter: "checked",
        type: "boolean",
        cellStyle: {
          width: 160,
          minWidth: 160,
        },
      },
      {
        title: "In District",
        field: "in_district",
        type: "boolean",
        cellStyle: {
          width: 160,
          minWidth: 160,
        },
      },
      {
        title: "Is Permitted",
        field: "is_permitted",
        type: "boolean",
        cellStyle: {
          width: 160,
          minWidth: 160,
        },
      },
      {
        title: "Well Contact",
        field: "well_contact",
        cellStyle: {
          width: 400,
          minWidth: 400,
        },
      },
      {
        title: "Well Sub-Class",
        field: "well_subclass",
        cellStyle: {
          width: 300,
          minWidth: 300,
        },
      },
      {
        title: "County",
        field: "county",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      {
        title: "Management Zone",
        field: "management_zone",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      {
        title: "District",
        field: "district",
        cellStyle: {
          width: 400,
          minWidth: 400,
        },
      },
      {
        title: "Aquifer Completion",
        field: "qa_completion",
        cellStyle: {
          width: 400,
          minWidth: 400,
        },
      },
      {
        title: "Well Address",
        field: "well_address",
        cellStyle: {
          width: 500,
          minWidth: 500,
        },
      },
      {
        title: "Latitude",
        field: "lat_dd",
        filtering: false,
      },
      {
        title: "Longitude",
        field: "lon_dd",
        filtering: false,
      },
      {
        title: "Is Removed",
        field: "removed",
        type: "boolean",
        cellStyle: {
          width: 160,
          minWidth: 160,
        },
      },
      {
        title: "Past Owners",
        field: "past_owners",
        cellStyle: {
          width: 300,
          minWidth: 300,
        },
      },
    ],
    [data, wellClassificationLookup] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    selectedWell,
    setSelectedWell,
    data,
    isFetching,
    error,
    refetch,
    selectedWellLabel,
    setSelectedWellLabel,
    WELLS_TABLE_COLUMNS,
  };
};
