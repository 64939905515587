import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Skeleton from "@material-ui/lab/Skeleton";
import { FieldServicesAppRoutes } from "../lib/constants";

// Root wrapper for pages
export const PageRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Container for page content
export const PageContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;

// App bar with logo and title
const StyledAppBar = styled(AppBar)`
  padding: 8px 16px;
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 48px;
  width: auto;
  margin-right: 16px;
`;

export const PageAppBar = () => {
  const { isXs } = useBreakpoints();
  const title = isXs ? "Field Services" : "BSEACD Field Services";
  const typographyVariant = isXs ? "h6" : "h5";

  return (
    <StyledAppBar position="static" color="default">
      <StyledToolbar>
        <Logo src="/static/img/bseacd-logo.png" alt="BSEACD Logo" />
        <Typography variant={typographyVariant}>{title}</Typography>
      </StyledToolbar>
    </StyledAppBar>
  );
};

// Title bar with page title and back button
const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  gap: 16px;
`;

const StyledTypography = styled(Typography)`
  flex-grow: 1;
  text-align: center;
`;

const StyledBackButton = styled(Button)`
  width: 225px;
`;

const StyledIconButton = styled(IconButton)`
  width: 48px;
  height: 48px;
`;

// Responsive back button
const BackButton = ({ to, label = "Back" }) => {
  const { isXs } = useBreakpoints();

  return isXs ? (
    <Tooltip title={label}>
      <StyledIconButton component={Link} to={to} color="inherit">
        <ArrowBack />
      </StyledIconButton>
    </Tooltip>
  ) : (
    <StyledBackButton
      component={Link}
      to={to}
      variant="outlined"
      startIcon={<ArrowBack />}
      color="inherit"
    >
      {label}
    </StyledBackButton>
  );
};

// TitleBar component with consistent spacing
export const TitleBar = ({ title, buttonPath, buttonLabel }) => {
  const { isXs } = useBreakpoints();
  const typographyVariant = isXs ? "h6" : "h5";

  return (
    <TitleContainer>
      <StyledTypography variant={typographyVariant}>{title}</StyledTypography>
      <BackButton to={buttonPath} label={buttonLabel} />
    </TitleContainer>
  );
};

// Button for navigation actions
const StyledNavigationButton = styled(Button)`
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  padding: 16px;
`;

export const NavigationButton = ({ label, to }) => {
  const { isXs } = useBreakpoints();
  const typographyVariant = isXs ? "h5" : "h4";

  return (
    <StyledNavigationButton
      component={Link}
      to={to}
      variant="contained"
      color="primary"
      fullWidth
    >
      <Typography variant={typographyVariant}>{label}</Typography>
    </StyledNavigationButton>
  );
};

const StyledCard = styled(Card)`
  margin-top: 120px;
  padding: 24px 16px;

  ${(props) => props.theme.breakpoints.only("sm")} {
    margin-top: 80px;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-top: 24px;
  }

  .MuiCardContent-root {
    text-align: center;
  }
`;

const ErrorButton = styled(Button)`
  margin-top: 16px;
  width: 120px;
`;

export const ErrorCard = ({
  title = "Something went wrong",
  message,
  onRetry,
  isFetching = false,
}) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} sm={9} md={8} lg={6}>
        <StyledCard>
          <CardContent>
            <Typography variant="h6" align="center" gutterBottom>
              {title}
            </Typography>
            {message && (
              <Typography variant="body2" align="center" gutterBottom>
                {message}
              </Typography>
            )}
            {onRetry && (
              <ErrorButton
                variant="outlined"
                color="secondary"
                onClick={onRetry}
                disabled={isFetching}
              >
                {isFetching ? "Retrying..." : "Retry"}
              </ErrorButton>
            )}
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

// Reusable skeleton loader for content and button
export const ContentSkeleton = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    p="32px"
    style={{ gap: "24px" }}
  >
    <Skeleton variant="rect" width="100%" height="100%" />
    <Skeleton variant="rect" width="225px" height="36px" />
  </Box>
);

// Reusable placeholder card and button
const StyledContentCard = styled(Card)`
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentPlaceholder = ({
  message = "Content goes here...",
  buttonLabel = "Save",
  wellId,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    p="32px"
    style={{ gap: "24px" }}
  >
    <StyledContentCard variant="outlined">
      <CardContent>
        <Typography variant="body1" align="center">
          {message}
        </Typography>
      </CardContent>
    </StyledContentCard>
    <Button
      variant="contained"
      color="primary"
      style={{ width: "225px" }}
      component={Link}
      to={FieldServicesAppRoutes.WELL_ACTIONS(wellId)}
    >
      {buttonLabel}
    </Button>
  </Box>
);

// Grid container for consistent layout
export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 2,
  justify: "center",
  alignItems: "stretch",
})`
  flex-grow: 1;
`;

// Grid item for layout consistency
export const GridItem = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
})`
  display: flex;
  flex-direction: column;
`;
