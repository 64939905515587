import { useQuery } from "react-query";
import { useAxiosInstance } from "./useAxiosInstance";
import { USE_QUERY_OPTIONS } from "../lib/constants";

export const useSelectedWell = (wellId) => {
  const axiosInstance = useAxiosInstance();

  return useQuery(
    ["well", wellId],
    async () => {
      const { data } = await axiosInstance.get(`/wells/${wellId}`);
      return data;
    },
    {
      ...USE_QUERY_OPTIONS,
      enabled: !!wellId,
    }
  );
};
