import { Link, Button } from "@material-ui/core";

export const ASSOCIATED_PERMITS_TABLE_COLUMNS = [
  {
    title: "Associated Permits",
    field: "permit_number",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/permit-records/permits-management/${rowData.permit_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.permit_number}
      </Button>
    ),
  },
];

export const OTHER_OWNER_WELLS_TABLE_COLUMNS = [
  {
    title: "Other Owner Wells",
    field: "district_well_id",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/wells/wells-management/${rowData.other_well_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.district_well_id}
      </Button>
    ),
  },
];

export const SITE_INSPECTION_RECORDS_TABLE_COLUMNS = [
  {
    title: "Site Inspection Records",
    //TODO fill in field
    field: "site_inspection_record_id",
    render: (rowData) => (
      <Button
        component={Link}
        to="#"
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.site_inspection_record_id}
      </Button>
    ),
  },
];

export const ENFORCEMENT_RECORDS_TABLE_COLUMNS = [
  {
    title: "Enforcement Records",
    //TODO fill in field
    field: "enforcement_record_id",
    render: (rowData) => (
      <Button
        component={Link}
        to="#"
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.enforcement_record_id}
      </Button>
    ),
  },
];

export const METERS_TABLE_COLUMNS = [
  {
    title: "Meters Attached to this Well",
    //TODO fill in field
    field: "meter_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/meter-readings/add-manage-meters/${rowData.meter_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.meter_id}
      </Button>
    ),
  },
];

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];

export const DEFAULT_BASIC_WELL_INFO_STATE = {
  well_ndx: null,
  removed: false,
  state_well_num: null,
  district_well_id: null,
  well_name: null,
  primary_use_ndx: 999,
  well_class_ndx: 999,
  well_subclass_ndx: 999,
  physical_location_address: null,
  city: null,
  contact_ndx: null,
  lat_dd: null,
  lon_dd: null,
  elevation_ft: null,
  created_timestamp: null,
  created_by: null,
  updated_timestamp: null,
  updated_by: null,
  removed_timestamp: null,
  removed_by: null,
  should_remove: false,
  is_published: false,
};

export const DEFAULT_ADDITIONAL_LOCATION_DETAILS_STATE = {
  in_district: null,
  district_ndx: 999,
  county_ndx: 999,
  well_reference_name: null,
  dist_property_line_ft: null,
  dist_septic_ft: null,
  directions_to_well: null,
};

export const DEFAULT_WELL_DETAILS_STATE = {
  index_well: null,
  secondary_use_ndx: 999,
  mgmtzone_ndx: 999,
  has_geo_log: null,
  well_status_ndx: 999,
  well_report_tracking_num: null,
  plugging_date: null,
  monitoring_status_ndx: 999,
  permitted_well: null,
  plugging_report_tracking_num: null,
  assoc_permit_ndx: [],
  assoc_inspection_record_ndx: [],
  assoc_enforcement_ndx: [],
};

export const DEFAULT_HYDROGEOLOGY_STATE = {
  aq_completion_ndx: 999,
  aquifer_ndx: 999,
  aq_setting_ndx: 999,
  //TODO change wagershed_ndx back to 999
  watershed_ndx: 1,
  formation_ndx: 999,
  available_logs_list: [],
};

export const DEFAULT_WELL_COMPLETION_STATE = {
  drilled_date: null,
  driller_ndx: 999,
  well_depth_ft: null,
  bore_diam_in: null,
  pump_depth_ft: null,
  pump_size_hp: null,
  pump_type: null,
  pump_gpm: null,
  notes_grout_types_methods: null,
  temp_new_driller: null,
  driller_add_new: false,
};

export const DEFAULT_ATTACHED_DOCUMENTS_AND_PHOTOS_STATE = {
  notes: null,
  photo_gallery: true,
  photo_date: null,
  tags: null,
  photo_taken_by: null,
};
