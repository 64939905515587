import {
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { applyOpacity, generateColorScale } from "../utils";
import { Paper } from "@material-ui/core";

const PieChart = ({
  title,
  data,
  labels,
  isLoading,
  error,
  controls,
  // onDownloadCSV,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const legendPosition = isSmall ? "top" : "right";

  const renderFallback = (message = "") => (
    <Paper
      variant="outlined"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "400px",
        width: "100%",
        textAlign: "center",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {message && (
            <Typography variant="h6" gutterBottom>
              {message}
            </Typography>
          )}
          {error && (
            <Typography color="error" variant="body2">
              Please try again later.
            </Typography>
          )}
        </>
      )}
    </Paper>
  );

  if (isLoading) return renderFallback();
  if (error)
    return renderFallback(`Error: ${error.message || "loading data."}`);
  if (!data || !data.length) return renderFallback("No data available.");

  const colors = generateColorScale(data.length);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors.map((color, i) =>
          hoveredIndex === null || i === hoveredIndex
            ? color
            : applyOpacity(color, 0.5)
        ),
        borderColor: colors.map((_, i) =>
          i === hoveredIndex ? "black" : "rgba(0, 0, 0, 0.1)"
        ),
        borderWidth: 2,
        hoverOffset: 20,
        radius: "90%",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: 20 } },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ label, raw, dataset }) => {
            const total = dataset.data.reduce(
              (acc, val) => acc + (val || 0),
              0
            );
            const percentage = raw ? ((raw / total) * 100).toFixed(2) : "0.00";
            return `${label}: ${
              raw ? raw.toLocaleString() : 0
            } (${percentage}%)`;
          },
        },
      },
      legend: {
        position: legendPosition,
        labels: {
          boxWidth: 20,
          generateLabels: (chart) => {
            const dataset = chart.data.datasets[0];
            const total = dataset.data.reduce(
              (acc, val) => acc + (val || 0),
              0
            );
            return chart.data.labels.map((label, i) => {
              const hidden = chart.getDatasetMeta(0).data[i]?.hidden;
              const value = dataset.data[i];
              const percentage = value
                ? ((value / total) * 100).toFixed(2)
                : "0.00";
              return {
                text: `${label}: ${
                  value ? value.toLocaleString() : 0
                } (${percentage}%)`,
                fillStyle:
                  hoveredIndex === null || i === hoveredIndex
                    ? dataset.backgroundColor[i]
                    : applyOpacity(dataset.backgroundColor[i], 0.5),
                strokeStyle:
                  i === hoveredIndex ? "black" : dataset.backgroundColor[i],
                hidden,
                index: i,
                textDecoration: hidden ? "line-through" : "none",
              };
            });
          },
        },
        onClick: (event, legendItem, legend) => {
          const chart = legend.chart;
          const index = legendItem.index;

          const meta = chart.getDatasetMeta(0);
          meta.data[index].hidden = !meta.data[index].hidden;
          chart.update();
        },
        onHover: (event, legendItem, legend) => {
          const chart = legend.chart;
          const index = legendItem.index;

          setHoveredIndex(index);
          chart.setActiveElements([{ datasetIndex: 0, index }]);
          chart.update();
        },
        onLeave: (event, legendItem, legend) => {
          const chart = legend.chart;

          setHoveredIndex(null);
          chart.setActiveElements([]);
          chart.update();
        },
      },
      title: {
        display: true,
        text: title,
        font: { size: 18, weight: "bold" },
        padding: { top: 10, bottom: 20 },
      },
    },
    onHover: (event, chartElement) => {
      const chart = event.chart;

      if (chartElement.length) {
        const index = chartElement[0].index;
        setHoveredIndex(index);
        chart.update();
      } else {
        setHoveredIndex(null);
        chart.update();
      }
    },
  };

  return (
    <Paper style={{ padding: "16px" }} variant="outlined">
      <Box height="400px" width="100%">
        <Pie data={chartData} options={options} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ gap: "8px" }}
      >
        {controls}
        {/*{onDownloadCSV && (*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    onClick={onDownloadCSV}*/}
        {/*    style={{ width: "200px" }}*/}
        {/*  >*/}
        {/*    Download CSV*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Box>
    </Paper>
  );
};

export default PieChart;
