import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import { Divider as MuiDivider, Paper, Typography } from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import MaterialTable from "material-table";
import Loader from "../../../../components/Loader";
import CopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../../../utils";
import { useApp } from "../../../../AppProvider";
import useFetchData from "../../../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);

function getYearBasedOnMonth() {
  const today = new Date();
  const currentMonth = today.getMonth(); // Note: January is 0, December is 11
  const currentYear = today.getFullYear();

  if (currentMonth >= 0 && currentMonth <= 7) {
    // Jan - August
    return currentYear;
  } else {
    return currentYear + 1;
  }
}

//388px
const MeterReadingsTracking = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [selectedSummary, setSelectedSummary] = React.useState({});

  const {
    data: dataSummary,
    isLoading: isLoadingSummary,
    error: errorSummary,
  } = useQuery(
    ["ui-meter-readings-entry-top-table"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-meter-readings-entry-top-table`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: dataTracking,
    isLoading: isLoadingTracking,
    error: errorTracking,
  } = useQuery(
    ["ui-meter-readings-tracking-detail"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-meter-readings-tracking-detail`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const [monthsLookup] = useFetchData("list-months", [], true);
  const monthsLookupForTable = useMemo(() => {
    let converted = {};
    if (monthsLookup?.length > 0) {
      monthsLookup.forEach((item) => {
        converted[item.month_number] = item.month_name;
      });
    }
    return converted;
  }, [monthsLookup]);

  const [PermitsLookup] = useFetchData("ui-list-permits", [], true);
  const permitsLookupForTable = useMemo(() => {
    let converted = {};
    if (PermitsLookup?.length > 0) {
      PermitsLookup.forEach((item) => {
        converted[item.permit_ndx] = item.permit_number_only;
      });
    }
    return converted;
  }, [PermitsLookup]);

  const columnsSummary = useMemo(
    () => [
      {
        title: "<0 Pumping",
        field: "has_negative",
        render: (rowData) =>
          rowData.has_negative === true
            ? "Yes"
            : rowData.has_negative === false
            ? "No"
            : "N/A",
        cellStyle: (value) => ({
          backgroundColor: value ? "red" : "inherit",
          color: value ? "white" : "inherit",
          fontWeight: value ? 600 : "inherit",
          border: "1px solid #eee",
        }),
        filtering: false,
      },
      {
        title: "Owner/Permit Name",
        field: "grouping_name",
      },
      {
        title: "Fiscal Year",
        field: "fiscal_year",
        defaultFilter: getYearBasedOnMonth().toString(),
      },
      {
        title: "Month Last Reported",
        field: "last_report",
        type: "date",
        filtering: false,
      },
      {
        title: "Permitted Gallons",
        field: "permitted_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.permitted_gallons),
        filtering: false,
      },
      {
        title: "YTD All Meters",
        field: "metered_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.metered_gallons),
        filtering: false,
      },
      {
        title: "FY Remaining",
        field: "remaining_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.remaining_gallons),
        filtering: false,
      },
      {
        title: "% Pumped",
        field: "pct_pumped",
        render: (rowData) => (rowData.pct_pumped * 100).toFixed(2) + "%",
        filtering: false,
      },
      {
        title: "Over 80%",
        field: "is_over80pct",
        render: (rowData) =>
          rowData.is_over80pct === true
            ? "Yes"
            : rowData.is_over80pct === false
            ? "No"
            : "N/A",
        cellStyle: (value) => ({
          backgroundColor: value ? "yellow" : "inherit",
          fontWeight: value ? 600 : "inherit",
          border: "1px solid #eee",
        }),
        filtering: false,
      },
      {
        title: "Over Allocation",
        field: "is_overpumped",
        render: (rowData) =>
          rowData.is_overpumped === true
            ? "Yes"
            : rowData.is_overpumped === false
            ? "No"
            : "N/A",
        cellStyle: (value) => {
          return {
            backgroundColor: value ? "orange" : "inherit",
            color: value ? "white" : "inherit",
            fontWeight: value ? 600 : "inherit",
            border: "1px solid #eee",
          };
        },
        filtering: false,
      },
      {
        title: "Permits",
        field: "grouped_permits",
        render: (rowData) => (
          <div>
            {rowData.grouped_permits.map((item, index) => (
              <div key={index}>{permitsLookupForTable[item]}</div>
            ))}
          </div>
        ),
        // eslint-disable-next-line
        customFilterAndSearch: (term, rowData) => {
          return rowData.grouped_permits.some((item) =>
            permitsLookupForTable[item]
              .toLowerCase()
              .includes(term.toLowerCase())
          );
        },
        cellStyle: {
          width: "175px",
          minWidth: "175px",
          border: "1px solid #eee",
        },
      },
    ],
    [permitsLookupForTable]
  );

  const filterTrackingData = useCallback(
    (data) => {
      if (!Object.keys(selectedSummary).length || !data) return data;
      return data.filter(
        (row) =>
          row.grouping_ndx === selectedSummary.grouping_ndx &&
          row.fiscal_year === selectedSummary.fiscal_year
      );
    },
    [selectedSummary]
  );

  const columnsTracking = useMemo(
    () => [
      {
        title: "Owner/Permit Name",
        field: "grouping_name",
      },
      {
        title: "Cal Year",
        field: "c_year",
        filtering: false,
      },
      {
        title: "Month",
        field: "c_month",
        lookup: monthsLookupForTable,
      },
      {
        title: "Permitted Gallons",
        field: "permitted_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.permitted_gallons),
      },
      {
        title: "Metered",
        field: "metered_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.metered_gallons),
      },
      {
        title: "YTD Metered",
        field: "cumulative_metered_gallons",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.cumulative_metered_gallons),
      },
      {
        title: "% Pumped",
        field: "pct_pumped",
        render: (rowData) => (rowData.pct_pumped * 100).toFixed(2) + "%",
        cellStyle: (value) => ({
          backgroundColor:
            value >= 0.8 && value < 1
              ? "yellow"
              : value >= 1
              ? "orange"
              : "inherit",
          color: value >= 1 ? "white" : "inherit",
          fontWeight: value >= 0.8 ? 600 : "inherit",
          border: "1px solid #eee",
        }),
      },
      {
        title: "Reduced Annual Permitted",
        field: "reduced_annual_volume",
        render: (rowData) =>
          new Intl.NumberFormat().format(rowData.reduced_annual_volume),
      },
      {
        title: "% of Reduced Annual",
        field: "pct_reduced_pumped",
        render: (rowData) =>
          (rowData.pct_reduced_pumped * 100).toFixed(2) + "%",
        cellStyle: (value) => ({
          backgroundColor: value > 1 ? "orange" : "inherit",
          color: value > 1 ? "white" : "inherit",
          fontWeight: value > 1 ? 600 : "inherit",
          border: "1px solid #eee",
        }),
      },
    ],
    [monthsLookupForTable, selectedSummary] // eslint-disable-line
  );

  if (errorSummary) return "An error has occurred: " + errorSummary.message;
  if (errorTracking) return "An error has occurred: " + errorTracking.message;
  return (
    <>
      <Helmet title="Meter Readings Tracking" />
      <Typography variant="h3" gutterBottom display="inline">
        Meter Readings Tracking
      </Typography>

      <Divider my={6} />
      <Paper>
        <TableWrapper>
          {dataSummary ? (
            <MaterialTable
              style={{
                position: "relative",
              }}
              id="annual-pumping-summary"
              title="Annual Pumping Summary"
              loading={isLoadingSummary}
              columns={columnsSummary}
              data={dataSummary}
              options={{
                filtering: true,
                emptyRowsWhenPaging: false,
                exportAllData: true,
                columnsButton: true,
                exportButton: { csv: true },
                pageSize: 30,
                pageSizeOptions: [5, 10, 30, 60],
                padding: "dense",
                searchFieldAlignment: "left",
                cellStyle: { border: "1px solid #eee" },
                minBodyHeight: "250px",
                maxBodyHeight: "500px",
              }}
              actions={[
                {
                  icon: CopyIcon,
                  tooltip: "Copy Data",
                  isFreeAction: true,
                  onClick: () => {
                    try {
                      copyToClipboard(columnsSummary, columnsSummary, () =>
                        doToast("success", "Data was copied to your clipboard.")
                      );
                    } catch (error) {
                      const message = error?.message ?? "Something went wrong";
                      doToast("error", message);
                    }
                  },
                },
                () => ({
                  icon: () =>
                    !dataTracking?.length ? (
                      <Loader />
                    ) : (
                      <span className="material-icons">visibility</span>
                    ),
                  tooltip: !dataTracking?.length
                    ? "Meter Readings Loading"
                    : "View Meter Readings",
                  onClick: (event, rowData) => {
                    setSelectedSummary(rowData);
                  },
                  disabled: !dataTracking?.length,
                }),
              ]}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Paper>

      {Object.keys(selectedSummary).length > 0 && (
        <Paper style={{ marginTop: "20px" }}>
          <TableWrapper>
            {dataSummary ? (
              <MaterialTable
                style={{
                  position: "relative",
                }}
                id="meter-readings-tracking"
                title="Monthly Pumping Details"
                loading={isLoadingTracking}
                columns={columnsTracking}
                data={filterTrackingData(dataTracking)}
                options={{
                  search: false,
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  exportAllData: true,
                  columnsButton: true,
                  exportButton: { csv: true },
                  pageSize: 30,
                  pageSizeOptions: [5, 10, 30, 60],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  cellStyle: { border: "1px solid #eee" },
                  minBodyHeight: "250px",
                  maxBodyHeight: "500px",
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(columnsTracking, columnsTracking, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                ]}
              />
            ) : (
              <Loader />
            )}
          </TableWrapper>
        </Paper>
      )}
    </>
  );
};

export default MeterReadingsTracking;
