import React from "react";
import { Bar } from "react-chartjs-2";
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";

const BarChart = ({
  title,
  labels,
  isLoading,
  error,
  controls,
  onDownloadCSV,
  datasets,
  chartOptions,
}) => {
  const chartData = {
    labels,
    datasets,
  };

  const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: 20 } },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        ticks: {
          callback: (value) => value.toLocaleString(),
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 18,
          weight: "bold",
        },
        padding: {
          top: 10,
          bottom: 20,
        },
      },
    },
  };

  const mergedOptions = {
    ...baseOptions,
    ...chartOptions,
    plugins: {
      ...baseOptions.plugins,
      ...(chartOptions?.plugins || {}),
    },
    scales: {
      ...baseOptions.scales,
      ...(chartOptions?.scales || {}),
    },
  };

  return (
    <Paper style={{ padding: "16px" }} variant="outlined">
      {isLoading || error || !datasets.length || !labels.length ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="400px"
          textAlign="center"
        >
          {isLoading && <CircularProgress />}
          {!isLoading && error && (
            <Typography color="error" variant="body2">
              Error loading data. Please try again later.
            </Typography>
          )}
          {!isLoading && !error && (!datasets.length || !labels.length) && (
            <Typography variant="h6" gutterBottom>
              No data available.
            </Typography>
          )}
        </Box>
      ) : (
        <Box height="400px">
          <Bar data={chartData} options={mergedOptions} />
        </Box>
      )}
      <Grid container alignItems="center" spacing={2}>
        {controls}
        {onDownloadCSV && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onDownloadCSV}
              style={{ width: "168px" }}
            >
              Download CSV
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default BarChart;
