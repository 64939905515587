import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { BASE_PATH } from "../lib/constants";

export const useAxiosInstance = () => {
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/api${BASE_PATH}`,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.error("Error getting Auth0 token", error);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
