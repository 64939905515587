import React from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { PERMITS_CHART_CONFIG } from "../constants";
import { usePermitsData } from "../hooks/usePermitsData";
import PieChart from "../charts/PieChart";
import { generateCSV } from "../utils";

const PermitsCharts = () => {
  const {
    chartType,
    setChartType,
    data,
    labels,
    chartData,
    isLoading,
    error,
    fiscalYears,
    selectedYear,
    setSelectedYear,
  } = usePermitsData();

  const controls = (
    <Grid container alignItems="center" spacing={2}>
      {/* Chart Type Selector */}
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="chart-type-label">Chart Type</InputLabel>
          <Select
            labelId="chart-type-label"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            label="Chart Type"
          >
            {Object.entries(PERMITS_CHART_CONFIG).map(([key, config]) => (
              <MenuItem key={key} value={key}>
                {config.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Year Selector */}
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="fiscal-year-label">Fiscal Year</InputLabel>
          <Select
            labelId="fiscal-year-label"
            value={selectedYear || ""}
            onChange={(e) => setSelectedYear(e.target.value)}
            label="Fiscal Year"
          >
            {fiscalYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const handleDownloadCSV = () => {
    const config = PERMITS_CHART_CONFIG[chartType];
    if (!config) return;

    const { csvHeaders, dataKeys } = config;
    const rows = data.map((item) => dataKeys.map((key) => item[key]));

    generateCSV(csvHeaders, rows);
  };

  const currentChartConfig = PERMITS_CHART_CONFIG[chartType];

  return (
    <PieChart
      title={currentChartConfig.label}
      data={chartData}
      labels={labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default PermitsCharts;
